<template>
  <div>
    <div
      v-if="loading"
      class="loader">
      <v-progress-circular
        :size="48"
        color="secondary"
        indeterminate/>
    </div>

    <CyFormsRole
      v-else
      ref="formsRole"
      :permissions-box-sub-heading="$t('subheadings.permissions')"
      :has-permissions-disabled="!$isCreationRoute"
      :saving="saving"
      :api-errors="errors"
      :item="apiKey"
      :has-owner-changed="hasOwnerChanged"
      @on-save="onSave"
      @on-delete="$toggle.show.deleteApiKeyModal"
      @on-cancel="setOwner">
      <CyAlert
        slot="alert"
        :content="$t('apiKeyNotificationMessage')"
        :button-label="$t('seeMore')"
        @click="navigateToDocumentation"/>

      <template slot="additional-form-detail-fields">
        <CySelectOwner
          v-model="owner"
          :readonly="!$cycloid.permissions.canDisplay('UpdateAPIKey', apiKeyCanonical)"
          :disabled="!$cycloid.permissions.canDisplay('UpdateAPIKey', apiKeyCanonical)"
          :current-owner="owner"/>

        <v-textarea
          v-if="!$isCreationRoute"
          :value="apiKey.last_seven"
          class="required-field"
          rows="2"
          readonly
          disabled
          no-resize
          required
          persistent-hint
          :hint="$t('apiKeyEndingWithHint')"
          :label="$t('endingWith')"/>
      </template>
    </CyFormsRole>

    <!-- Delete Modal -->
    <CyModal
      v-if="show.deleteApiKeyModal"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-text="$t('forms.btnRemove')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.show.deleteApiKeyModal(false)"
      small
      modal-type="delete">
      <p class="ma-0">
        {{ $t('confirmDeleteSentence') }}
      </p>
    </CyModal>

    <!-- Create Modal -->
    <CyModal
      v-if="show.createdApiKeyModal && !_.$isEmpty(apiKey.token)"
      :header-title="$t('createdApiKeyModalTitle')"
      :cancel-btn-func="cancel"
      :cancel-btn-text="$t('forms.btnClose')"
      cancel-btn-color="primary"
      action-btn-hidden
      small
      modal-type="success">
      <div class="api-key-success-modal d-flex flex-column justify-center align-center">
        <div>
          <p class="h5">
            <strong>{{ $t('apiKeySuccessMessageTitle') }}</strong>
          </p>
          <p v-html="$sanitizeHtml($t('apiKeySuccessMessageContent'))"/>
        </div>
        <div class="api-key-success-modal__access-token pa-6 grey lighten-3 rounded width-100">
          <v-textarea
            :value="apiKey.token"
            class=" required-field"
            auto-grow
            readonly
            disabled
            required
            :label="$t('accessToken')">
            <CyCopyBtn
              ref="copyBtn"
              slot="append"
              :copy-value="apiKey.token"/>
          </v-textarea>
        </div>
      </div>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyCopyBtn from '@/components/copy-btn.vue'
import CyFormsRole from '@/components/forms/role.vue'
import CySelectOwner from '@/components/select-owner.vue'
import { constructBreadcrumb, getOwnerUsername } from '@/utils/helpers'

export default {
  name: 'CyPageApiKey',
  components: {
    CyCopyBtn,
    CyFormsRole,
    CySelectOwner,
  },
  breadcrumb () {
    const { $isCreationRoute, apiKey } = this
    const header = $isCreationRoute
      ? this.$t('addApiKey')
      : apiKey?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.apiKeys'),
        name: 'apiKeys',
      },
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    const { $isCreationRoute, $date, apiKey, loading } = this
    const { name, owner, last_used: lastUsed } = this.apiKey || {}

    return $isCreationRoute
      ? { title: this.$t('addApiKey') }
      : {
          title: name,
          owner,
          metas: [
            lastUsed ? this.$t('lastUsed', [$date.$formatTimeAgo(apiKey?.last_used)]) : this.$t('neverUsed'),
          ],
          loading,
        }
  },
  props: {
    apiKeyCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    show: {
      createdApiKeyModal: false,
      deleteApiKeyModal: false,
      detailsBox: false,
    },
    saving: false,
    loading: true,
    owner: null,
  }),
  computed: {
    ...mapState('organization/apiKey', {
      apiKey: (state) => state.detail,
      errors: (state) => state.errors,
    }),
    ...mapGetters(['orgMembers']),
    hasOwnerChanged () {
      if (this.$isCreationRoute || _.isEmpty(this.apiKey?.owner)) return false
      return !_.isEqual(
        getOwnerUsername(this.apiKey?.owner),
        getOwnerUsername(this.owner),
      )
    },
  },
  async mounted () {
    const { apiKeyCanonical } = this

    if (this.$isCreationRoute) this.setInitialOwner()
    else {
      await this.GET_API_KEY({ apiKeyCanonical })
      this.setOwner()
    }

    this.$setOriginalData()
    this.loading = false
  },
  destroyed () {
    this.RESET_API_KEY_STATE()
  },
  methods: {
    ...mapActions('organization/apiKey', [
      'CREATE_API_KEY',
      'DELETE_API_KEY',
      'GET_API_KEY',
      'UPDATE_API_KEY',
    ]),
    ...mapMutations('organization/apiKey', [
      'RESET_API_KEY_STATE',
    ]),
    async onSave ({ rules, name, description }) {
      const { $isCreationRoute, owner, apiKeyCanonical, username } = this
      this.saving = true

      const apiKey = {
        name,
        description,
        canonical: $isCreationRoute ? this.$getSlug(name) : apiKeyCanonical,
        owner: owner?.username ?? username,
        ...($isCreationRoute ? { rules } : {}),
      }

      $isCreationRoute
        ? await this.CREATE_API_KEY({ apiKey })
        : await this.UPDATE_API_KEY({ apiKey })

      if (!_.$isEmpty(this.errors)) this.scrollToTop()

      if ($isCreationRoute) this.$toggle.show.createdApiKeyModal(true)

      this.saving = false
    },
    async onDelete () {
      const { apiKey, $router } = this
      this.deleting = true

      await this.DELETE_API_KEY({ apiKey, $router })

      if (!_.$isEmpty(this.errors)) this.scrollToTop()

      this.$toggle.show.deleteApiKeyModal(false)
      this.deleting = false
    },
    cancel () {
      this.$toggle.show.createdApiKeyModal(false)

      const { canonical: apiKeyCanonical } = this.apiKey
      if (apiKeyCanonical) this.$router.replace({ name: 'apiKey', params: { apiKeyCanonical } })
    },
    scrollToTop () {
      const cyDetailsEl = _.$get(this.$refs, 'formsRole.$el', {})
      const cyDetailsContentEl = cyDetailsEl.querySelector('.cy-details__content')

      cyDetailsContentEl.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    navigateToDocumentation () {
      window.open($docLinks.apiKeys.index, '_blank')
    },
    setInitialOwner () {
      this.owner = this.orgMembers.find(({ username }) => username === this.username)
    },
    setOwner () {
      this.owner = this.apiKey?.owner || {}
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:APIKey details',
        accessToken: 'Access token',
        addApiKey: 'Create @:APIKey',
        apiKeyEndingWithHint: 'This matches the last characters of the key and only help you identify the @:APIKey . The full key is only displayed during @:APIKey creation.',
        apiKeyNotificationMessage: '@:APIKeys can be used to authenticate requests to the API over Basic Authentication.',
        apiKeySuccessMessageContent: 'Be sure to <strong>copy the access key</strong> below. It’s only displayed upon creation, and you won’t be able to retrieve it later.',
        apiKeySuccessMessageTitle: 'Your @:APIKey has been successfully created.',
        confirmDeleteHeader: 'Remove @:APIKey',
        confirmDeleteSentence: 'Do you really want to remove this @:APIKey?',
        createdApiKeyModalTitle: '@:APIKey created',
        endingWith: 'Ending with',
        lastUsed: 'Last used {0}',
        subheadings: {
          permissions: 'Define what this @:APIKey can do. For each entity type, control access to specific actions and resources.',
        },
        neverUsed: 'Never used',
      },
      es: {
        title: 'Detalles de la @:APIKey',
        accessToken: 'Token de acceso',
        addApiKey: 'Crear @:APIKey',
        apiKeyEndingWithHint: 'Esto coincide con los últimos caracteres de la clave y solo lo ayuda a identificar la @:APIKey . La clave completa solo se muestra durante la creación de la @:APIKey .',
        apiKeyNotificationMessage: 'Las @:APIKeys se pueden utilizar para autenticar solicitudes a la API a través de la autenticación básica.',
        apiKeySuccessMessageContent: 'Asegúrese de <strong> copiar la clave de acceso </strong> a continuación. Solo se muestra al crearse y no podrá recuperarlo más tarde.',
        apiKeySuccessMessageTitle: 'Su @:APIKey se ha creado correctamente.',
        confirmDeleteHeader: 'Quitar @:APIKey',
        confirmDeleteSentence: '¿Realmente desea eliminar esta @:APIKey?',
        createdApiKeyModalTitle: 'Se creó la @:APIKey',
        endingWith: 'Terminando con',
        lastUsed: 'Ultimo uso {0}',
        subheadings: {
          permissions: 'Defina lo que puede hacer esta @:APIKey . Para cada tipo de entidad, controle el acceso a acciones y recursos específicos.',
        },
        neverUsed: 'Nunca utilizado',
      },
      fr: {
        title: 'Détails de la @:APIKey',
        accessToken: `Jeton d'accès`,
        addApiKey: 'Créer une @:APIKey',
        apiKeyEndingWithHint: `Cela correspond aux derniers caractères de la clé et vous aide uniquement à identifier la @:APIKey . La clé complète n'est affichée que lors de la création de la @:APIKey .`,
        apiKeyNotificationMessage: `Les @:APIKeys peuvent être utilisées pour authentifier les demandes adressées à l'API via l'authentification de base.`,
        apiKeySuccessMessageContent: `Veillez à <strong>copier la clé d'accès</strong> ci-dessous. Elle s’affiche uniquement lors de sa création et vous ne pourrez pas la récupérer ultérieurement.`,
        apiKeySuccessMessageTitle: 'Votre @:APIKey a été créée avec succès.',
        confirmDeleteHeader: 'Supprimer la @:APIKey',
        confirmDeleteSentence: 'Voulez-vous vraiment supprimer cette @:APIKey?',
        createdApiKeyModalTitle: '@:APIKey créée',
        endingWith: 'Se terminant par',
        lastUsed: 'Dernière utilisation {0}',
        subheadings: {
          permissions: `Définissez ce que cette @:APIKey peut faire. Pour chaque type d'entité, contrôlez l'accès à des actions et des ressources spécifiques.`,
        },
        neverUsed: 'Jamais utilisé',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.api-key-success-modal__access-token {
  ::v-deep {
    .v-label,
    textarea {
      color: cy-get-color("grey", "dark-2");
    }
  }
}
</style>
